import React from "react";
import "./styles.css"


const Privacy = ()=>{
    return(
        <div className="privacy_mensagem">
        <h1>Privacy Policy</h1>
            
            <h2>Introduction</h2>
            <p>Welcome to our website. Your privacy is extremely important to us. 
                This document describes how we collect, use, and protect your personal information. 
                We want to assure you that we do not use cookies that store data on your device.</p>
            
            <h2>Information Collection</h2>
            <p>Our website does automatically collect any personally identifiable information. But We do not use cookies, only google analytics</p>
            
            <h2>Use of Google Analytics</h2>
        <p>We use Google Analytics to track and report website traffic. 
            Google Analytics uses cookies to collect data that helps us understand how visitors interact with our website. 
            This information is used to compile reports and improve the site.</p>
        <p>For more information on Google's privacy practices, visit <a href="https://policies.google.com/privacy">Google Privacy & Terms</a>.</p>

        <h2>How We Use Your Information</h2>
        <p>We use the collected information for various purposes, including:</p>
        <ul>
            <li>To provide and maintain our website</li>
            <li>To gather analysis or valuable information so that we can improve our website</li>
        </ul>

        <h2>Security of Your Information</h2>
        <p>We take the security of your information seriously and implement appropriate measures to protect it. 
            However, no method of transmission over the internet or method of electronic storage is 100% secure, 
            and we cannot guarantee its absolute security.</p>

        <h2>Changes to This Privacy Policy</h2>
        <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. 
            You are advised to review this privacy policy periodically for any changes.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this privacy policy, please contact us:</p>
        <ul>
            <li>By email: ivo@nathivos.com.br</li>
        </ul>
            <h2>Effective Date</h2>
            <p>This privacy policy is effective as of 01/06/2024.</p>
            
            <p>Thank you for trusting our services and valuing your privacy as much as we do.</p>

        </div>
    )
}
export default Privacy;