import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    borderRadius: "8px",
    bgcolor: '#DDD',
    border: '1px solid #BBB',
    boxShadow: 24,
    textAlign: 'center',
    p: 3,
};

export default function RatingModal({name, id_post}) {
    const [msg, setMsg] = useState("")
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [ip, setIp] = useState(null)

    useEffect(()=>{
        fetch("https://api.ipify.org/?format=json")
        .then(r => r.json())
        .then(r => setIp(r.ip))
    },[])
    return (
        <div>
            <Button onClick={handleOpen}>{name}</Button>
            
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="rating-modal" variant="h5" component="h2">
                        {!!msg ? "Obrigado!":"Deixe sua avaliação"}
                    </Typography>
                    <Typography id="rating-modal-description" sx={{ mt: 2 }}>
                        <Rating
                            name="stars"
                            precision={0.5}
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                                const data = new FormData()
                                data.append("ip", ip)
                                data.append("id_post", id_post)
                                data.append("rating", newValue)
                                fetch("https://nathivos.com.br/myblogueserver/insert/u446777955_app/rating",{
                                    method: "POST",
                                    body: data
                                })
                                .then(r => r.json())
                                .then(r => {
                                    if(r.status === "sucesso"){
                                        setMsg("Salvo com sucesso!")

                                    }
                                })
                            }}
                            readOnly={!!msg}
                        />
                    </Typography>
                    <Typography id="rating-modal-legend" variant="h6" component="h6">
                        {/* {msg} */}
                        {msg && <Button onClick={handleClose}>Fechar</Button>}
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}
