import React from "react";
import img404 from "../assets/pg404.png"
import "./styles.css"


const ErrorPage = ()=>{
    return(
        <div className="error_mensagem">
            <img src={img404} alt="" />
        </div>
    )
}
export default ErrorPage;