import React, { useContext } from "react";
import "./styles.css"
// import { Link } from "react-router-dom";
import ThemeContext from "./themeContext";
import languageContext from "./change-language";


const Footer = ()=>{
    const {dark} = useContext(ThemeContext);
    const {language} = useContext(languageContext);

    return(
        <div className={dark ? "footer_dark":"footer"}>
            <div className="footeresq"></div>
            <div className="footermeio">&copy; 2024 Nathivo's</div>
            <div className="footerdir">
                {/* <Link to="/privacy">Privacy</Link> */}<br/>
                <i onClick={() => window.location = 'mailto: ivobiesdorf@gmail.com'}>
                    {language === "pt" ? <strong>Contato: </strong> : 
                     language === "en" ? <strong>Contact: </strong>:<strong>Kontakt: </strong>}
                     ivo@nathivos.com
                </i>
                <span>
                    {language==="pt" ? "Desenvolvido por ": language==="en"? "Developed by ":"Entwickelt bei "}Ivo Biesdorf
                </span>
            </div>
        </div>
    )
}
export default Footer