import React, { useEffect, useState, useContext } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./styles.css"
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link, useLocation } from "react-router-dom";
import { Loading } from '../components/Loading';
import languageContext from "../components/change-language"
import AdsComponent from '../components/AdsComponent';
import ThemeContext from "../components/themeContext";
import Footer from "../components/Footer"


const Home = ()=>{
    // const initialState = {id:"", name:"", name_english:"", name_german:"", country:"", address:"", address_english:"", address_german:"", photo:"", short_description:"", short_description_english:"", short_description_german:"", general_description:"", general_description_english:"", general_description_german:"", status:"0"}
    const [posts, setPosts] = useState([{}])
    const [loading, setLoading] = useState(true)
    const FOTOS = "https://nathivos.com.br/myblogueserver/fotos/"
    let listItems = "";
    const {language} = useContext(languageContext);
    const {dark} = useContext(ThemeContext);
    const location = useLocation().pathname 
    // const setTitle = (namept, nameen)=>{language === "pt" ? document.title = namept : document.title = nameen}
    const voidItems = <div className="post"><h1 className="name">Desculpe, ainda não temos nada aqui!</h1> </div>
       
    useEffect(()=>{
        const url = location === "/" ? ("https://www.nathivos.com.br/myblogueserver/select/u446777955_app/posts/where/status/igual/1/orderby/rand()/desc/limit/4"
                                    ):(
                                      "https://www.nathivos.com.br/myblogueserver/select/u446777955_app/posts/where/country/igual"+ location +"/orderby/rand()/desc/"
                                    )
        try {
            fetch(url)
            .then(r => r.json())
            .then(r => {
                if(r.status === 'erro'){
                    setPosts([{}])
                }else{
                    setPosts(r)
                }
                setLoading(false)
            })
        } catch (error) {
            
        }
    },[location])
    try {
        listItems = posts.filter(p=>p.status === "1").map((p) =>
            <div className="content_post"  key={Math.random()}>
                <aside className="aside"><AdsComponent dataAdSlot="3088029193"/></aside>
                <Link className= {dark ? "post_dark":"post"} to= {"/"+p.country +"/" + p.id+"/"+(p.name_english).toLowerCase().replaceAll(" ", "_").replaceAll("-", "_")} >
                    <h1 className="name">{language === "pt" ? p.name : ( language === "en" ? p.name_english: p.name_german)}</h1>
                    {p.address !== "" ? (<span className="address"><i className="fa fa-map-marker fa-lg" aria-hidden="true"> </i> {language === "pt" ? p.address : ( language === "en" ? p.address_english: p.address_german)}</span>):("")}
                    <div className="image_content">
                        <div className="image">
                            <LazyLoadImage
                                alt=""
                                effect="blur"
                                src={(FOTOS + p.photo)}
                                width="100%"
                            /> 
                        </div>
                    </div>
                    <div className="short_description">
                        {language === "pt" ? p.short_description : ( language === "en" ? p.short_description_english: p.short_description_german)}
                    </div>
                    <div className="lermais">
                        {language === "pt" ? "Leia mais..." : ( language === "en" ? "Read more...": "Mehr lesen...")}
                    </div>
                    
                    <AdsComponent dataAdSlot="3088029193"/>
                </Link>
                <aside className="aside"><AdsComponent dataAdSlot="3088029193"/></aside>
            </div>
        );
    } catch (error) {
       console.log("Erro");
    }
    
    return(
        <main className={dark ? "content_dark":"content"} >
            <section className="mainsection"> 
                {loading ? <Loading/> : listItems.length === 0 ? voidItems:listItems}
            </section>
            <Footer/>
        </main>
    )
}
export default Home;