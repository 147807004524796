import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Home from './routes/Home';
import ErrorPage from './routes/ErrorPage';
import Post from './routes/Post';
import Privacy from "./routes/Privacy"
import "./assets/fontawesome/css/font-awesome.min.css"

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <Home />
            },
            {
                path: ':country',
                element: <Home />
            },
            {
                path: ':country/:id/:name',
                element: <Post />
            },
            {
                path: "privacy",
                element: <Privacy />
            }
        ]
    }
])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

