import './styles.css'
import logo from './carregando.gif'

export const Loading = () => {
    return(
        <div className='loading' key="010101010">
            <img src={logo} alt="loading..." />
            {/* <i className="fa fa-spinner fa-pulse fa-4x fa-fw" aria-hidden="true"></i> */}
        </div>
    )
}