import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ThemeContext from "./themeContext";


const NavItem = ({navActive, navegation, name, to})=>{
    const {dark} = useContext(ThemeContext);
    return(
        <Link 
            key={Math.random()}
            className={navActive !== navegation ? (dark ? "menu_item_dark":"menu_item") : (dark ? "menu_item_active_dark":"menu_item_active")} 
            onClick={()=>to(navegation)} 
            to={"/"+ ((navegation === "home") ? "" : navegation)}>
            {name} 
        </Link>
    )
    
}
export default NavItem