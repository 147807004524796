import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import './styles.css';
import brazil from '../assets/brazil.png';
import germany from '../assets/deutchland.png';
import england from '../assets/england.png';
import languageContext from "./change-language";
import ThemeContext from "./themeContext";
import NavItem from "./NavItem";
import ReactGA from "react-ga4";
import Cookies from 'js-cookie';

ReactGA.initialize("UA-180861541-1");

const Navbar = ()=>{
    const {language, setLanguage} = useContext(languageContext);
    const {dark, setDark} = useContext(ThemeContext);
    const [navitem, setNavitem] = useState("home")
    const [menuitens, setMenuitens] = useState([{}])
    const [menu, setMenu] = useState(["menu2", "menu2"])
    const [menu1, setMenu1] = useState("menu2")
    const param = useParams().country 
    const location = useLocation().pathname 
    const titulo = {home:"Dicas quentes pra curtir dentro e fora de casa", brazil:"Conheça as maravilhas do Brasil", germany:"Conheça os principais pontos turisticos da Alemanha", uruguay:"Viaje com a gente pelo Uruguai", switzerland:"A Suíça além do chocolate", movies:"As melhores dicas de filmes pra não largar do sofá", series:"As melhores dicas de séries pra não largar do sofá", others:"Dicas pra você!"}
    const title = {home:"Hot tips for enjoying indoors and outdoors", brazil:"Discover the wonders of Brazil", germany:"Discover the main tourist attractions in Germany", uruguay:"Travel with us around Uruguay", switzerland:"Switzerland beyond chocolate", movies:"The best movie tips to keep you from leaving the couch", series:"The best series tips to keep you from leaving the couch", others:"Hot tips for enjoying indoors and outdoors"}
    
    useEffect(()=>{
        
        ReactGA.send({ hitType: "pageview", page: location});
        document.title = param === undefined ? "Dicas quentes pra curtir dentro e fora de casa": language === "pt" ? titulo[param]:title[param];
        try {
            fetch("https://www.nathivos.com.br/myblogueserver/selectespecial")
            .then(r => r.json())
            .then(r => {
                setMenuitens(r)
            })
        } catch (error) {
            alert("Erro ao carregar os dados")
        }finally{
            const url = param === undefined ? location : param;
            setNavitem(url === "/"? "home" : url.replace("/",""))
            const cookie = Cookies.get('theme')
            setDark(cookie === "true" ? true : false )
        }
    },[param])

    const isinarray = (item)=>{
        const arrayitens = menuitens.map(i => i.country)
        if(arrayitens.indexOf(item) > -1){
            return true
        }else{
            return false
        }
    }
    const handleTheme = ()=>{
        Cookies.set('theme', !dark);
        setDark(!dark) 
    }
    const handleMenu = ()=>{
        setMenu(menu[0] === "menu" ? ["menu2","menu2"] : ["menu","menu_dark"]); 
        setMenu1(menu1 === "menu1" ? "menu2" : "menu1")
    }
    return(
        <nav className={dark ?"navbar_dark":"navbar"} key={Math.random()}>
            <header className="logo">
                <div className={dark ? "logo_text_dark":"logo_text"}>
                    Nathivo's
                </div>
                <div className={dark ? "theme_box_light":"theme_box_dark"}  title={dark ? "Disable Dark mode":"Enable Dark mode"}
                    onClick={()=>handleTheme()}>
                    {!dark && <i className="fa fa-moon-o fa-lg" ></i>}
                    {dark && <i className="fa fa-sun-o fa-lg" >  </i>}
                </div>
            </header>
            <div className="menu_mobil" onClick={handleMenu}>
                <i className="fa fa-bars fa-2x" aria-hidden="true"></i>
            </div>
            <div className={menu1} onClick={()=>{setMenu(["menu2", "menu2"]); setMenu1("menu2")}}>
                <div className={dark ? menu[1]:menu[0]} >
                    <NavItem
                        navActive={navitem} 
                        navegation="home"
                        name={language === "pt" ? "Início" : language === "en" ? "Home":"Home" }
                        to={setNavitem}
                    />
                    {isinarray("germany") && <NavItem 
                        navActive={navitem} 
                        navegation="germany"
                        name={language === "pt" ? "Alemanha" : language === "en" ? "Germany":"Deutschland"}
                        to={setNavitem}
                    />}
                    {isinarray("brazil") && <NavItem 
                        navActive={navitem} 
                        navegation="brazil"
                        name={language === "pt" ? "Brasil" : language === "en" ? "Brazil":"Brasilien"}
                        to={setNavitem}
                    />}
                    {isinarray("switzerland") &&  <NavItem 
                        navActive={navitem} 
                        navegation="switzerland"
                        name={language === "pt" ? "Suiça" : language === "en" ? "Switzerland":"Schweiz"}
                        to={setNavitem}
                    />}
                    {isinarray("uruguay") &&  <NavItem 
                        navActive={navitem} 
                        navegation="uruguay"
                        name={language === "pt" ? "Uruguai" : language === "en" ? "Uruguay":"Uruguay"}
                        to={setNavitem}
                    />}
                    {isinarray("movies") &&  <NavItem 
                        navActive={navitem} 
                        navegation="movies"
                        name={language === "pt" ? "Filmes" : language === "en" ? "Movies":"Filmen" }
                        to={setNavitem}
                    />}
                    {isinarray("series") &&  <NavItem 
                        navActive={navitem} 
                        navegation="series"
                        name={language === "pt" ? "Séries" : language === "en" ? "Series":"Serien" }
                        to={setNavitem}
                    />}
                    {isinarray("others") &&  <NavItem 
                        navActive={navitem} 
                        navegation="others"
                        name={language === "pt" ? "Outros" : language === "en" ? "Others":"Andere"}
                        to={setNavitem}
                    />}
                    
                    {/* <Link className={navitem !== "contact" ? "menu_item":"menu_item_active"}  
                        onClick={()=>setNavitem("contact")}to="/contact">
                        {language === "pt" ? "Contato" : language === "en" ? "Contact":"Kontakt" }
                    </Link>  */}
                    
                    <div className="dropdown" >
                        <button className="dropbtn" onClick={e => e.stopPropagation()}><img src={language === "pt" ? brazil : (language === "en" ? england : germany)} alt="" /></button>
                        <div className={dark ? "dropdown-content_dark":"dropdown-content"}>
                            <div className={dark ? "dropdown_item_dark":"dropdown_item"} onClick={()=>{setLanguage("pt")}}>
                                <span>Português</span>
                                <img src={brazil} alt="" />
                            </div>
                            <div className={dark ? "dropdown_item_dark":"dropdown_item"} onClick={()=>setLanguage("en")}>
                                <span>English</span>
                                <img src={england} alt="" />
                            </div>
                            <div className={dark ? "dropdown_item_dark":"dropdown_item"} onClick={()=>setLanguage("de")}>
                                <span>Deutsch</span>
                                <img src={germany} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </nav>
    )
}
export default Navbar;