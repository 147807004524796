import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AdsComponent from '../components/AdsComponent';
import languageContext from "../components/change-language"
import ThemeContext from "../components/themeContext";
import Footer from "../components/Footer"
import Modal from '../components/Ratingmodal';
import Ratingview from "../components/Ratingview";
// import { Helmet } from 'react-helmet';

const Posts = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState("error")
    const [post, setPost] = useState([{ id: 0, id_post: '', post_name: "", maps_link: "", video_link: "", post_type: "", photo: '', description: '' }])
    const [posts, setPosts] = useState([{ id: 0, name: '', photo: '', short_description: '' }])
    const [rating, setRating] = useState({media: 0, qtd: 0, status:""})
    const FOTOS = "https://nathivos.com.br/myblogueserver/fotos/"
    const { language } = useContext(languageContext);
    const { dark } = useContext(ThemeContext)
    // const pais = useParams().country
    // const name = useParams().name
    const setTitle = (namept, nameen)=>{language === "pt" ? document.title = namept : document.title = nameen}
    // onClick={()=>setTitle(p.name, p.name_english)}

    const stringToHtml = (pt, en, de) => {
        const string = (language === "pt" ? pt : (language === "en" ? en : de))
        if (string !== undefined) {
            const novastring = string.split("\n")
            const novoHtml = novastring.map((s) => {
                return <p key={Math.random()}> {s}</p>
            })
            return novoHtml
        }
    }
    

    useEffect(() => {
        // document.title = name === undefined ? "Nathivos" : "Nathivos/" + pais + "/" + name;
        try {
            fetch("https://nathivos.com.br/myblogueserver/select/u446777955_app/post/where/id_post/igual/" + id)
            .then(r => r.json())
            .then(r => {
                if (r.status === "erro") {
                    setError("error")
                } else {
                    setPost(r)
                    setError("ok")
                    fetch("https://nathivos.com.br/myblogueserver/select/u446777955_app/posts/where/id/igual/" + id)
                        .then(r => r.json())
                        .then(r => {
                            if (r.status !== "erro") {
                                setPosts(r)
                                setError("ok")
                                
                                fetch("https://nathivos.com.br/myblogueserver/selectrating/u446777955_app/" + id)
                                .then(r => r.json())
                                .then(r => {
                                    if(r.status === "sucesso"){
                                        setRating(r)
                                        // console.log(r);
                                    }
                                })
                            } else {
                                setError("error")
                            }
                        })
                }
            })
        } catch (error) {

        }

    }, [id])
    const VoltarHome = () => {
        return navigate("/");
    }
    const listItems = (error === "ok") && post.map((p) =>
        <div className="content_post" key={Math.random()}>
            <aside className="aside"> <AdsComponent dataAdSlot="3088029193" /> </aside>
            <div className={dark ? "post_details_dark" : "post_details"}>
                {p.post_type === "1" ? (
                    <>
                        <div className="post_name">{language === "pt" ? p.post_name : (language === "en" ? p.post_name_english : p.post_name_german)}</div>
                        <div className="image_content">
                            <div className="image">
                                <LazyLoadImage
                                    alt=""
                                    effect="blur"
                                    src={(FOTOS + p.photo)}
                                    width="100%"
                                />
                            </div>
                        </div>
                        <i>{language === "pt" ? p?.subtitle : (language === "en" ? p?.subtitle_english : p?.subtitle_german)}</i>
                        <strong className="short_description">{stringToHtml(p.description, p.description_english, p.description_german)}</strong>
                        {p.extern_reference ? (
                            <div className="refence">
                                <a href={p.extern_reference} className="link">Referências(Link externo)</a>
                            </div>
                        ) : ("")}
                    </>
                ) : (
                    p.post_type === "2" ? (
                        <div className="post_coringa_row">
                            <div className="post_name">{language === "pt" ? p.post_name : (language === "en" ? p.post_name_english : p.post_name_german)}</div>
                            <div className="image_content2">
                                <div className="image">
                                    <LazyLoadImage
                                        alt="Image"
                                        effect="blur"
                                        src={(FOTOS + p.photo)}
                                        width="100%"
                                    />
                                </div>
                            </div>
                            <strong className="short_description2">{stringToHtml(p.description, p.description_english, p.description_german)}</strong>
                            <i>{language === "pt" ? p?.subtitle : (language === "en" ? p?.subtitle_english : p?.subtitle_german)}</i>
                            {p.extern_reference ? (
                                <div className="refence">
                                    <a href={p.extern_reference} className="link">Referências(Link externo)</a>
                                </div>
                            ) : ("")}
                        </div>
                    ) : (
                        <>
                            <div className="post_name">{language === "pt" ? p.post_name : (language === "en" ? p.post_name_english : p.post_name_german)}</div>
                            {p.maps_link && <div className="maps_content">
                                <iframe
                                    className="maps"
                                    src={p.maps_link}
                                    width="100%"
                                    height="100%"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    aria-hidden="false"
                                    tabIndex="0"
                                    title="Maps Google"
                                />
                            </div>}
                            {p.video_link && <div className="video_content">
                                <iframe
                                    className="video"
                                    src={p.video_link}
                                    title="YouTube video player"
                                    width="100%"
                                    height="100%"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen=""
                                />
                            </div>}
                            <strong className="short_description">{stringToHtml(p.description, p.description_english, p.description_german)}</strong>
                            {p.extern_reference ? (
                                <div className="refence">
                                    <a href={p.extern_reference} className="link">Referências(Link externo)</a>
                                </div>
                            ) : ("")}
                        </>
                    )
                )}
            </div>
            <aside className="aside"> <AdsComponent dataAdSlot="3088029193" /> </aside>
        </div>
    );
    return (
        <main className={dark ? "content_dark" : "content"} key={Math.random()}>
            {/* {document.meta["description"] = language === "pt" ? posts[0].short_description : (language === "en" ? posts[0].short_description_english : posts[0].short_description_german)} */}
            {/* <Helmet>
                <meta property="og:title" content={language === "pt" ? posts[0].name : (language === "en" ? posts[0].name_english : posts[0].name_german)} />
                <meta property="og:description" content={language === "pt" ? posts[0].short_description : (language === "en" ? posts[0].short_description_english : posts[0].short_description_german)} />
                <meta property="og:image" content={FOTOS + posts[0].photo} />
            </Helmet> */}
            <section className="mainsection">
                {(error === "ok") &&
                    <div className="content_post">
                        {setTitle(posts[0].name, posts[0].name_english)}
                        <aside className="aside"> <AdsComponent dataAdSlot="3088029193" /> </aside>
                        <article className={dark ? "post_details_dark" : "post_details"} key={Math.random()}>
                            <div className="name">{language === "pt" ? posts[0].name : (language === "en" ? posts[0].name_english : posts[0].name_german)}</div>
                            <span className="rating_content">
                                <Ratingview rating={rating.media} /> 
                                {rating.qtd === '0' ?" Nenhuma avalição ": rating.qtd === '1' ? " 1 avalição":rating.qtd + " avalições"}
                            </span>
                            <span className="modal_content"><Modal name="Avalie" id_post = {posts[0].id}/></span>
                            <div className="general_description">{stringToHtml(posts[0].general_description, posts[0].general_description_english, posts[0].general_description_german)}</div>
                        </article>
                        <aside className="aside"> <AdsComponent dataAdSlot="3088029193" /> </aside>
                    </div>
                }
                {listItems}
                {(error === "error") && (
                    <article key={Math.random()}>
                        <div className="no_registers">{language === "pt" ? " Sem registros!" : (language === "en" ? "No matches!" : "Nichts gefunden!")}</div>
                        <div className="coringa">
                            <button className="btn_back_home" onClick={VoltarHome}>{language === "pt" ? "Voltar p/ Início" : (language === "en" ? "Back to Home" : "Zurück zu Home")}</button>
                        </div>
                    </article>
                )}
            </section>
            <Footer />
        </main>
    )
}
export default Posts;